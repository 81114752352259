import React from "react"
// import InViewMonitor from "react-inview-monitor"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import GatsbyBackgroundImage from "gatsby-background-image"

// import { useStore } from "@hooks/useStore"
import styled from 'styled-components'

function FluidImage({ data, ...props }) {

    return (
        <GatsbyImage
            fluid={data.childImageSharp.fluid}
            // loading='eager'//lazy, eager, auto
            // fadeIn={false}
            placeholderStyle={{opacity: 0.1}}
            {...props}
        />
    )
}

function _BackgroundFluidImage({ children, data, ...props }) {
    // if( data && data.match && data.match(/http/g) ){
    //     return <div style={{
    //         width: '100%',
    //         height: '100%',
    //         position: 'absolute',
    //         top: 0, left: 0,
    //         display: 'block',
    //         zIndex: 0,
    //     }} {...props}>
    //         {children}
    //         <div style={{
    //             background: `url(${data})`,
    //             width: '100%',
    //             height: '100%',
    //             // position: 'absolute',
    //             // top: 0, left: 0,
    //             display: 'block',
    //             zIndex: -1,
    //         }} />
    //     </div>
    // }
    return (
        <GatsbyBackgroundImage
            // src={data.url}
            fluid={data.childImageSharp.fluid}
            loading='eager'//lazy, eager, auto
            // fadeIn={true}
            // backgroundColor='#000'
            {...props}
        >
            {children}
        </GatsbyBackgroundImage>
    )
}

const BackgroundFluidImage = styled(_BackgroundFluidImage)`
  &::before,
  &::after {
    // filter: invert(80%)
    transition: none;
  }
`

export { FluidImage, BackgroundFluidImage }