import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { globalHistory } from '@reach/router'
import { gsap, Power4 } from 'gsap'

import LinkList from '@projects/LinkList'
import * as Anim from "@atoms/animation/Animation"
import { TransitionLink } from "@atoms/Link"
import { menuData, footerMenuData　} from '@utility/UrlData'
import { observer } from 'mobx-react-lite'
import { ModalMenuState } from '@status/ModalMenuState'
import { useRecoilState } from "recoil"

import css from "@css/projects/p-modal-menu.module.styl"

export default observer( function ModalMenu()
{
    // const { uiStore } = useRootStore()
    // console.log(uiStore)
    // console.log('store', store)
    // console.log('store', store.hoge)

    const [isOpen, setIsOpen] = useRecoilState(ModalMenuState)
    const [isRuning, setIsRuning] = React.useState(false)

    let filColor = ["#2B9A86","#2B9A86"]

    const buttonRef = React.useRef()
    const topBorderRef = React.useRef()
    const bottomBorderRef = React.useRef() 
    const menuRef = React.useRef()
    const bgRef = React.useRef()
    const q = useStaticQuery(graphql`
        query {
            menu : file(relativePath: { eq: "Header__btn__menu.svg" }) { publicURL }
        }
    `)

    //0: closed 1:opend 2: transing

    // React.useEffect(()=>{
    //     appStore.modalMenu = isOpen
    // },[isOpen])

    React.useEffect(() =>{

        const onComplate = () => {
            setIsRuning(false)
        }

        if ( isOpen === 0){
            let topTl = gsap.timeline()
            topTl
                .set(topBorderRef.current, {
                    rotate: '-135deg',
                    x: 31,
                    y: 25,
                })
                .to(topBorderRef.current, {
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 0,
                })
                .set(topBorderRef.current, {
                    rotate: '0deg',
                    x: 0,
                    y: 0,
                })
                .to(topBorderRef.current, {
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 1.0,
                })

            let borderTl = gsap.timeline()
            borderTl
                .set(bottomBorderRef.current, {
                    rotate: '135deg',
                    x: 36,
                    y: -15,
                })
                .to(bottomBorderRef.current, {
                    delay: 0.15,
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 0,
                })
                .set(bottomBorderRef.current, {
                    rotate: '0deg',
                    x: 0,
                    y: 0,
                })
                .to(bottomBorderRef.current, {
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 1.0,
                })

            let menuTl = gsap.timeline()
            menuTl
                .to(menuRef.current, {
                    duration: 0.9,
                    ease: Power4.easeInOut,
                })
                .set(menuRef.current,{
                    display: 'none',
                })
                .to(bgRef.current, {
                    ease: Power4.easeIn,
                    duration: 0.4,
                    scaleY: 0.1,
                })
                .to(bgRef.current, {
                    ease: Power4.easeOut,
                    duration: 0.4,
                    scaleY: 0.0,
                    scaleX: 0.0,
                    onComplete: onComplate,
                })
        } else if( isOpen === 1 ) {
            let topTl = gsap.timeline()
            topTl
            .to(topBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 0,
                x: 44,
            })
            .set(topBorderRef.current, {
                rotate: '45deg',
                x: 7,
                y: -6,
            })
            .to(topBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 1.0,
            })

            let borderTl = gsap.timeline()
            borderTl
            .to(bottomBorderRef.current, {
                delay: 0.15,
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 0,
                x: 44,
            })
            .set(bottomBorderRef.current, {
                rotate: '-45deg',
                x: 4,
                y: 10,
            })
            .to(bottomBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 1.0,
            })
            let menuTl = gsap.timeline()
            menuTl
                .set(menuRef.current, {
                    display: 'flex',
                })
                // .set(bgRef.current, {
                //     // borderRadius: `1000 0 1000 1000`
                // })
                .to(bgRef.current,{
                    ease: Power4.easeIn,
                    duration: 0.4,
                    scaleX: 1.0,
                    scaleY: 0.1,
                    // borderRadius: `0 0 1000 1000`
                })
                .to(bgRef.current, {
                    ease: Power4.easeOut,
                    duration: 0.4,
                    scaleY: 1.0,
                    // borderRadius: `0 0 0 0 `,
                    onComplete: onComplate
                })
                // let lis = menuRef.current.querySelectorAll('li')
                // for(const item of lis){
                //     console.log(item)
                // }
                
        }
    },[isOpen, isRuning.current])

    React.useEffect(()=>{
        console.log('update...')
    })

    const menuHandler = ()=>{
        if (isRuning === true) { return }
        if (isOpen === 0 || isOpen === -1) {
            setIsRuning(true)
            setIsOpen(1)
        } else if(isOpen === 1){
            setIsRuning(true)
            setIsOpen(0)
        }
    }
    

    return (
        <React.StrictMode>
            <div className={css.pModalMenuBg} ref={bgRef}></div>
            <button className={css.pModalMenuButton} onClick={menuHandler} ref={buttonRef}>
                {/* <Svg src={q.menu.publicURL} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g id="Header__btn__menu" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <rect ref={topBorderRef} width="40" height="5" x="0" y="13" fill={filColor[0]} />
                        <rect ref={bottomBorderRef} width="40" height="5" x="0" y="25" fill={filColor[0]} />
                    </g>
                </svg>
            </button>
            <nav className={css.pModalMenu}
                style={{display: 'none' }}
                ref={menuRef}
            >
                <div className={css.pModalMenu__inner}>
                    <div className={css.pModalMenu__list}>
                        <Anim.Event start={isOpen === 1 ? true : false} type="ModalMenu" delay={0.5}>
                        <ul>
                            {menuData.map(({ en,label, to, scroll, blank }, i) => {
                                return (
                                    <li key={i} className={css.pModalMenu__list__item}>
                                        <TransitionLink to={to} scroll={scroll} blank={blank} onClick={menuHandler}>
                                            <div className={css.inner}>
                                                <span className={css.label1}>
                                                    {en}
                                                </span>
                                                <span className={css.label2}>
                                                    {label}
                                                </span>
                                            </div>
                                        </TransitionLink>
                                    </li>
                                )
                            })}
                            <li className={css.pModalMenu__list__item}>
                                <TransitionLink to='/contact/' onClick={menuHandler}>
                                    <div className={css.inner}>
                                        <span className={css.label1}>
                                            Contact
                                        </span>
                                        <span className={css.label2}>
                                            お問い合わせ
                                        </span>
                                    </div>
                                </TransitionLink>
                            </li>
                        </ul>
                        </Anim.Event>
                    </div>
                    <Anim.Event className={css.pModalMenu__footer} start={isOpen === 1 ? true : false} type="Fade">
                        <footer>
                                <LinkList className={css.pModalMenu__footer__menu} data={footerMenuData} />
                                <p className={css.pModalMenu__footer__copyright}>
                                    ©2020 DaisenKensetsu inc.
                                </p>
                        </footer>
                    </Anim.Event>
                </div>
            </nav>
            
        </React.StrictMode>
    )
})
