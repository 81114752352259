import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
import { motion, AnimatePresence } from 'framer-motion'
import { useEventListener } from '@hooks/useEventListener'

// import ThreeJs from '@components/Three/Main.js'
// import Cursor from '@components/Cursor'
import Header from '@blocks/Header'
import Footer from "@blocks/Footer"
import Loading from "@projects/Loading"
import ModalMenu from "@projects/ModalMenu"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'

import '@css/style.styl'

export const TransitionContext = React.createContext()

const options = {
    duration: 1.6,
    delay: 0.6,
}

export default function TransitionLayout({ children, location })
{
    const [first, setFirst] = React.useState(true)
    const [transitionState, setTranstionState] = React.useState(3)
    const key = location.pathname
    const duration = options.duration// + 10
    const delay = options.delay + first ? 1000.0 : 0

    const params = {
        initial: {
            position: 'relative',
            // opacity: 1,
        },
        enter: {
            transition: {
                duration: duration,
                delay: delay,
                when: 'beforeChildren',
            },
            // opacity: 1,
        },
        exit: {
            transition: { duration: duration },
            // opacity: 1,
        },
    }
    

    React.useEffect(() => {
        // 0: none, 1:start, 2:loading, 3: complete
        //start with TransitionLink.
        console.log('test',transitionState)
        //init

        if (transitionState === 1) {
            console.log('[[[ Transition <<< Show Start >>> ]]]')
        } else if (transitionState === 2) {
            console.log('[[[ Transition <<< Loading Start >>> ]]]')
        } else if (transitionState === 3) {
            console.log('[[[ Transition <<< Loading Complete >>> ]]]')
            setTranstionState(4)
        } else if (transitionState === 4) {
            console.log('[[[ Transition <<< Hide Start >>> ]]]')
            setTimeout(() => {
                setTranstionState(0)
            }, (options.duration + options.delay) * 1000)
        }
    }, [transitionState])

    //browser back push action
    useEventListener('popstate', () => {
        setTranstionState(0)
    })

    return (
        <TransitionContext.Provider value={{
            transitionState: transitionState,
            setTranstionState: setTranstionState,
            duration: options.duration,
            delay: options.delay,
        }}>
            <div id="root-container" className="root-container">
                <Loading />
                <Header />
                <ModalMenu />
                <AnimatePresence
                    onExitComplete={() => {
                        if (transitionState === 1) {
                            setTranstionState(2)
                        }
                    }}
                    exitBeforeEnter
                >
                    <motion.div
                        key={key}
                        variants={params}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        onAnimationStart={() => {
                            if( first === true ){
                                setFirst(false)
                            }
                        }}
                        onAnimationComplete={() => {
                            if (transitionState === 2) {
                                setTranstionState(3)
                            }
                        }}
                    >
                        {children}
                        <Footer />
                    </motion.div>
                </AnimatePresence>
            </div>
        </TransitionContext.Provider>
    )
}
