import React from "react"
import { graphql, useStaticQuery } from "gatsby"    
import { TransitionLink } from "@atoms/Link"
import { globalHistory } from '@reach/router'
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import LinkList from '@projects/LinkList'
import { menuData } from '@utility/UrlData'
import * as Anim from "@atoms/animation/Trigger"

import css from "@css/layouts/l-header.module.styl"
import LogoSvg from "@images/header__logo__daisen-white.svg"

export default function Header()
{
    // const { appStore } = useStore()
    const { location } = globalHistory

    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "header__logo__daisen.svg" }) {
                publicURL
            }
            logo_w : file(relativePath: { eq: "header__logo__daisen-white.svg" }) {
                publicURL
            }
        }
    `)

    return(
        <Anim.Trigger className={css.container} type="Header" trigger="top" loop>
            <header id="header">
                <div className={`${css.inner}`} id="header__inner">
                    <div className={css.left}>
                        <TransitionLink className={css.logo} to="/">
                            <span className={'logo_black'}><img alt="DAISEN" src={q.logo.publicURL}/></span>
                            <span className={'logo_white'}><img alt="DAISEN" src={q.logo_w.publicURL} /></span>
                        </TransitionLink>
                    </div>
                    <div className={css.right}>
                        <LinkList className={css.menu}
                            data={menuData}
                            location={location}
                            currentItemClass={'active'}/>
                    </div>
                </div>
            </header>
        </Anim.Trigger>
    )
}

// function Menu(){
//     const menuLinks = useMenuLink()
//     return(
//         <nav className={css.menu}>
//             {
//                 menuLinks.map( ({label,to},i) => {
//                     return(
//                         <span key={i} className={css.item}>
//                             <TransitionLink to={to}>{label}</TransitionLink>
//                         </span>
//                     )
//                 })
//             }
//         </nav>
//     )
// }
