
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { TransitionLink } from "gatsby"
// import Image from 'gatsby-image'
import { gsap, Power4 } from 'gsap'
import { TransitionContext } from '@layouts/TransitionLayout'
// import LogoSvg from "@images/header__logo__daisen-white.svg"
import css from "@css/projects/p-loading.module.styl"


export default function Loading(props)
{
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "loading__logo__daisen-white.svg" }) {
                publicURL
            }
        }
    `)
    const ref = React.useRef()
    const titleRef = React.useRef()
    const textRef = React.useRef()
    const transition = React.useRef({
        mode: -1,
        duration: 0,
        delay: 0,
    })
    const loading = React.useRef(true)
    const first = React.useRef(false)
    let { text } = props


    React.useEffect(() => {
        //hide loading
        if (transition.current.mode === 4) {
            if (!loading.current) {
                return
            }
            loading.current = false
            let firstVal = first.current ? 0 : 1.6
            gsap.set(ref.current, {
                x: `0%`,
            })
            gsap.to(ref.current, {
                ease: Power4.easeInOut,
                delay: transition.current.delay + firstVal,
                duration: transition.current.duration,
                // opacity: 0,
                display: 'none',
                x: `100%`,
                onComplete: () => {
                    gsap.set(ref.current, {
                        x: `-100%`,
                    })
                }
            })
            //title
            gsap.to(titleRef.current, {
                delay: transition.current.duration + firstVal,
                duration: transition.current.duration/2,
                ease: Power4.easeIn,
                opacity: 0,
                x: `50%`,
                onComplete: () => {
                    gsap.set(titleRef.current, {
                        x: `-50%`,
                        opacity: 0,
                    })
                }
            })
            first.current = true
        } else
        if(transition.current.mode === 1){
            //start loading
            if (loading.current) {
                return
            }
            loading.current = true
            gsap.to(ref.current, {
                ease: Power4.easeInOut,
                duration: transition.current.duration,
                // opacity: 1,
                display: 'flex',
                x: `0%`,

            })
            //title
            gsap.to(titleRef.current, {
                delay: transition.current.duration/2,
                duration: transition.current.duration,
                ease: Power4.easeOut,
                opacity: 1,
                x: `0%`,
            })
        }

        if (transition.current.mode === -1) {
            gsap.set(ref.current, {
                x: `0%`,
                display: 'flex',
            })
            gsap.to(ref.current, {
                ease: Power4.easeInOut,
                delay: transition.current.delay,
                duration: transition.current.duration,
                // opacity: 0,
                display: 'none',
                x: `100%`,
                onComplete: () => {
                    gsap.set(ref.current, {
                        x: `-100%`,
                    })
                }
            })
            //title
            gsap.set(titleRef.current, {
                opacity: 1,
                x: `0%`,
            })
            gsap.to(titleRef.current, {
                delay: transition.current.duration,
                duration: transition.current.duration / 2, 
                ease: Power4.easeIn,
                opacity: 0,
                x: `50%`,
                onComplete: () => {
                    gsap.set(titleRef.current, {
                        x: `-50%`,
                        opacity: 0,
                    })
                }
            })
        }
    })

    

    return (
        <TransitionContext.Consumer>
            {({ transitionState, setTranstionState, duration, delay }) => {
                transition.current.mode = transitionState
                transition.current.duration = duration
                transition.current.delay = delay
                return (
                    <div className={css.container} ref={ref}>
                        <div className={css.inner}>
                            <div className={css.loading} ref={titleRef}>
                                {/* <LogoSvg/> */}
                                <div className={css.logo}><img alt="DAISEN" src={q.logo.publicURL} /></div>
                                <div className={css.text}>地域の未来をつくる。</div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </TransitionContext.Consumer>
    )
}
