import React from "react"
import { TransitionLink } from "@atoms/Link"

export default function LinkList({ className,data,location, currentItemClass,...props }) {
    return (
        <div className={className}>
            <ul>
                { data.map(({ label, to, scroll, blank}, i) => {
                    let active = false
                    if( location && location.pathname !== "/"){
                        active = to.indexOf(location.pathname) === 0 ? true : false
                    }
                    return (
                        <li key={i} className={`${active && currentItemClass ? currentItemClass : ''}`}>
                            <TransitionLink to={to} scroll={scroll} blank={blank} {...props}>{label}</TransitionLink>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
