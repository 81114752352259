import React, { useEffect } from "react"
import { navigate } from 'gatsby-link'
import { globalHistory } from '@reach/router'
import { TransitionContext } from '@layouts/TransitionLayout'
import { SmoothScroll } from '@hooks/useSmoothScroll'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
// import { modalMenuState } from '@states/modal-menu-state'

// import { useStore } from '@hooks/useStore'
const TransitionLink = ({
	children,
	to,
	blank,
	scroll,
	onClick,
	...other
}) => {
	// let modalMenuValue = useRecoilValue(modalMenuState)
	// let setModalMenuValue = useSetRecoilState(modalMenuState)

	let isInternal = /^\/(?!\/)/.test(to)
	let isHash = /^(!?#)/.test(to)
	// const { appStore } = useStore()

	if( /.+\.jpeg|pdf|.docx/.test(to) ){
		isInternal = false
	}

	if (isHash || scroll) {
		isInternal = true
	}

	const { location } = globalHistory

	const onClickHandler = (e, transitionState, setTranstionState, duration, delay) => {
		e.stopPropagation()
		e.preventDefault()
		if( transitionState !== 0){ return }
		if (location.pathname === to || !to) {
			if (scroll) {
				// console.log(scroll)
				onClick && onClick()
				SmoothScroll(scroll)
			} else {
				onClick && onClick()
				SmoothScroll('#root-container')
			}
			return
		} else {

			onClick && onClick()
			setTranstionState(1)
			// if( modalMenuValue === 1){
			// 	setModalMenuValue(0)
			// }
			setTimeout(() => {
				navigate(`${to}`)
				if (scroll) {
					setTimeout(() => {
						SmoothScroll(scroll)
					}, 1000)
				}
			}, duration * 1000)
		}
	}

	// useEffect(()=>{
	// })

	return (
		<TransitionContext.Consumer>
			{({ transitionState, setTranstionState, duration, delay }) => {
				if (isInternal) {
					return (
						<a
							to={to}
							onClick={(e) => {
								onClickHandler(e, transitionState, setTranstionState, duration, delay)
							}}
							{...other}
						>
							{children}
						</a>
					)
				} else {
					return (
						<a href={to} target={blank && "_blank"} {...other}>
							{children}
						</a>
					)
				}
			}}
		</TransitionContext.Consumer>
	)
}
export { TransitionLink }