import React, { useEffect } from "react"
import { gsap, Power4, Elastic } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import css from "@css/atoms/a-animation/index.module.styl"
gsap.registerPlugin(ScrollTrigger)

let defalutName = (type) => css[`aAnimation${type}`]
let activeName = (type) => css[`aAnimation${type}Active`]
// let reverseName = (type) => `aAnimation${type}Reverse`

export const Css = css
export const Item = ({ children }) => {
    return <div className={css.item}>{children}</div>
}
export const Trigger = ({ children, className="", type = "Fade", loop = false, delay=0, trigger}) =>
{
    const ref = React.useRef()
    // React.useLayoutEffect(()=>{
    //     if (typeof window !== 'undefined') {
    //         window.scrollTo(0, 0)
    //     }
    //     ScrollTrigger.update()
    // })
    useEffect(() => {
        if( trigger ){
            const triggerElement = document.getElementById(trigger)
            if (!triggerElement){
                gsap.set(ref.current, {
                    className: `${className} ${defalutName(type)}`
                })
                return
            }
        }
        const triggerStr = trigger ? `#${trigger}` : false
        gsap.to(ref.current, {
            scrollTrigger: {
                scrub: 3.0,
                trigger: triggerStr || ref.current,
                end: "bottom top",
                once: false,
                toggleClass: { targets: ref.current, className: `${activeName(type)}` }
            },
        })
    })

    return (
        <div ref={ref} className={`${className} ${defalutName(type)}`}>
            { children}
        </div>
    )
}

// パララックス
export const PraraxSlide = ({ children, container, className, x}) => {
    const ref = React.useRef()
    useEffect(() => {
        gsap.to(ref.current , {
            x: x || 100,
            ease: "none",
            scrollTrigger: {
                trigger: container.current,
                start: "top bottom",
                end: "bottom top",
                scrub: 1.0,//遅延っぽ
            },
        });
    })

    return (
        <div ref={ref} style={{ position: 'rerative' }} className={className}>
            { children}
        </div>
    )
}

export const PraraxImage = ({ children, container, className, }) => {
    const ref = React.useRef()
    useEffect(() => {
        gsap.fromTo(ref.current,{
            y: `25%`,
            scale: 1.5,
        },{
            y: `0%`,
            scale: 1.1,
            // transformOrigin: `50% 50%`,
            ease: "none",
            scrollTrigger: {
                trigger: container.current,
                start: "top bottom",
                end: "bottom top",
                scrub: 1,//遅延っぽ
            },
        });
    })

    return (
        <div ref={ref} style={{ position: 'rerative' }} className={className}>
            { children}
        </div>
    )
}


export const FadeIn = ({ children, container, ...props}) =>
{
    const ref = React.useRef()
    useEffect(()=>{
        gsap.fromTo(ref.current, {
            x: 100,
            opacity: 0
        }, {
            duration: 1.2,
            x: 0,
            opacity: 1,
            ease: Power4.easeOut,
            scrollTrigger: {
                trigger: ref.current,
                start: 'top center+=100',
                toggleActions: 'play none none none'
            }
        });
    })

    return (
        <div ref={ref} {...props}>
            { children }
        </div>
    )
}

export const FadeIn2 = ({ children, container, delay, ...props }) => {
    const ref = React.useRef()
    useEffect(() => {
        gsap.set(ref.current, {
            y: -100,
            opacity: 0,
        })
        gsap.to(ref.current, {
            delay: 0.3,
            y: 0,
            opacity: 1,
            duration: 1.2,
            ease: Power4.easeOut,
            scrollTrigger: {
                trigger: ref.current,
                start: 'top center',
                toggleActions: 'play none none none'
            }
        });
    })

    return (
        <div ref={ref} {...props}>
            { children}
        </div>
    )
}

export const FadeIn3 = ({ children, container, delay, ...props }) => {
    const ref = React.useRef()
    useEffect(() => {
        gsap.set(ref.current, {
            opacity: 0,
        })
        gsap.to(ref.current, {
            opacity: 1,
            duration: 1.2,
            ease: Power4.easeOut,
            scrollTrigger: {
                trigger: ref.current,
                start: 'top center',
                toggleActions: 'play none none none'
            }
        });
    })

    return (
        <div ref={ref} {...props}>
            { children}
        </div>
    )
}

export const ScaleIn = ({ children, container, delay, ...props }) => {
    const ref = React.useRef()
    useEffect(() => {
        gsap.set(ref.current, {
            scale: 0,
        })
        gsap.to(ref.current, {
            scale: 1,
            delay: 0.6,
            duration: 0.6,
            ease: Elastic.easeOut.config(1,0.5),
            scrollTrigger: {
                trigger: ref.current,
                start: 'top center',
                toggleActions: 'play none none none'
            }
        });
    })

    return (
        <div ref={ref} {...props}>
            { children}
        </div>
    )
}

/* ========================================================================
*
*    hero animations
*
======================================================================== */
// export const hero = ({ children, container, ...props }) =>
// {
//     const ref = React.useRef()
//     useEffect(() => {
//         gsap.to(ref.current, {
//             delay: 3.0,
//             duration: 1.0,
//             y: 100,
//         })
//     })

//     return (
//         <div ref={ref}>
//             { children}
//         </div>
//     )
// }
