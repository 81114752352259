// Hook
const menuData = [
    {
        en: 'About',
        label: '大泉グループとは',
        to: '/about/',
        blank: false,
    },
    {
        en: 'Information',
        label: '企業情報',
        to: '/info/',
        blank: false,
    },
    {
        en: 'CSR活動',
        label: '取り組み',
        to: '/csr/',
        blank: false,
    },
    {
        en: 'News',
        label: 'ニュース',
        to: '/news/',
        blank: false,
    },
    {
        en: 'Recruit',
        label: '採用情報',
        to: '/recruit/',
        blank: false,
    },
    {
        en: 'Contact',
        label: 'お問い合わせ',
        to: '/contact/',
        blank: false,
    }
]
const footerMenuData = [
    {
        label: 'QEO',
        to: '/news/20220701-2/',
        blank: 'false',
    },
    {
        label: 'プライバシーポリシー',
        to: '/privacy-policy/',
        blank: 'false',
    }
]

export { menuData, footerMenuData }