import React, { Children } from "react"
import Mailto from '@atoms/SendMail'
import SendMail from "./SendMail"

export default function ContactButton({ children, className}) {
    return (
        <SendMail
            className={className}
            obfuscate={true}
            email='info@daisen-hogefuga.jp'
            headers={
                {
                    cc: '',
                    bcc: '',
                    subject: '【大泉グループ ホームページ】お問い合わせ',
                    body: `【会社名/団体名】*

【ご担当者名】*

【電話番号/FAX】*

【お問合せ内容】*
                    `
                }
            }>
                {children}
        </SendMail>
    )
}
