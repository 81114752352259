import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage } from "@atoms/Image"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import LinkList from '@projects/LinkList'
import SendMailButton from '@atoms/SendMailButton'
import { menuData, footerMenuData } from '@utility/UrlData'
import LogoSvg from "@images/header__logo__daisen.svg"

import css from "@css/layouts/l-footer.module.styl"

export default function Footer()
{
    const q = useStaticQuery(graphql`
        query {
            prerry : file(relativePath: { eq: "footer__img__perry-johnson-registrars.png" }) {
                childImageSharp {
                    fluid(maxWidth: 280) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            jab : file(relativePath: { eq: "footer__img__jab.png" }) {
                childImageSharp {
                    fluid(maxWidth: 280) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
   
    return(
        <footer className={css.container}>
            <div className={css.inner}>
                <div className={css.contact}>
                    <div className={css.inner}>
                        <p className={css.tel}>Tel:<strong>0175-74-2468</strong></p>
                        <p className={css.text}>電話受付：平日9時〜17時</p>
                        {/* <p className={css.mail}>Mail: 
                            <SendMailButton>info@daisen-hogefuga.co.jp</SendMailButton>
                        </p> */}
                        <TransitionLink className={css.button} to="/contact/">お問い合わせ</TransitionLink>
                    </div>
                </div>
                <div className={css.info}>
                    <div className={css.section}>
                        {/* <div className={css.sticker}>
                            <FluidImage className={css.prerry} data={q.prerry} />
                            <FluidImage className={css.jab} data={q.jab} />
                        </div> */}
                        <div className={css.logo}><LogoSvg /></div>
                    </div>
                    {/* <div className={css.isoId}>大泉建設株式会社 [ISO 9001:2015] [ISO 14001:2015]</div> */}
                    <div className={css.isoId}>大泉建設株式会社</div>
                    <LinkList className={css.menu} data={menuData}/>
                </div>
            </div>
            <div className={css.infobar}>
                <div className={css.inner}>
                    <LinkList className={css.menu} data={footerMenuData} />
                    <div className={css.copyright}>
                        ©2020 DaisenKensetsu inc.
                    </div>
                </div>
            </div>
        </footer>
    )
}

// function Menu() {
//     const menuLinks = useMenuLink()
//     return (
//         <nav className={css.menu}>
//             { menuLinks.map(({ label, to }, i) => {
//                 return (
//                     <TransitionLink key={i} className={css.item} to={to}>{label}</TransitionLink>
//                 )
//             })}
//         </nav>
//     )
// }

// function SmallMenu(){
//     const footerLinks = useFooterLink()
//     return(
//         <nav className={css.menu}>
//             { footerLinks.map(({ label, to }, i) => {
//                 return (
//                     <TransitionLink key={i} className={css.item} to={to}>{label}</TransitionLink>
//                 )
//             })}
//         </nav>
//     )
// }